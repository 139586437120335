import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SbEditable from "storyblok-react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import DynamicComponent from "../components/DynamicComponent"
import useStoryblok from "../lib/storyblok"

const NotFoundPage = ({ location }) => {
  let components = null
  let story = useStoryblok(null, location)

  if (story) {
    components = story.content.body.map(blok => {
      return <DynamicComponent blok={blok} key={blok._uid} />
    })
  }

  return (
    <Layout>
      <Seo title="Home" pageName="404" />
      <SbEditable content={story ? story.content : false}>
        <h1>{story ? story.content.title : "Not Found"}</h1>
        {components}
        <StaticImage
          src="../images/gatsby-astronaut.png"
          width={300}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="A Gatsby astronaut"
          style={{ marginBottom: `1.45rem` }}
        />
      </SbEditable>
    </Layout>
  )
}

export default NotFoundPage
